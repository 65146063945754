import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { CloseButton, Logo } from "../assets/icons/sidebar";
import { sidebarSelector, toggleCloseSidebar } from "../redux/reducers/sidebar";
import { AppRoutes } from "../services/constants";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Button } from "../reusables";

const data = [
  { id: 1, text: "Who We Are", link: AppRoutes.whoWeAre },
  { id: 2, text: "How We Work", link: AppRoutes.howWeWork },
  { id: 3, text: "Our Culture", link: AppRoutes.ourCulture },
  { id: 4, text: "CSR", link: AppRoutes.csr },
  { id: 6, text: "Our Services", link: AppRoutes.ourServices },
  { id: 5, text: "Projects", link: AppRoutes.project },
  { id: 7, text: "Blog", link: AppRoutes.blog },
  { id: 8, text: "Contact Us", link: AppRoutes.contactUs },
  // { id: 9, text: "Listings", link: AppRoutes.listings },
];
const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sidebarActive } = useSelector(sidebarSelector);
  const [show, setShow] = React.useState(false);
  return (
    <Container showSidebar={sidebarActive}>
      <div className="sidebar">
        <div data-aos="fade-left" data-aos-duration="500" className="header">
          <img src={Logo} alt="" />
          <ToggleBtn
            src={CloseButton}
            onClick={() => dispatch(toggleCloseSidebar())}
          />
        </div>
        <SidebarLinks
          className="underline"
          onClick={() => {
            dispatch(toggleCloseSidebar());
            navigate(AppRoutes.home);
          }}
        >
          Home
        </SidebarLinks>
        <SidebarLinks onClick={() => setShow(!show)}>
          About Us
          {show ? <FaChevronUp /> : <FaChevronDown />}
        </SidebarLinks>
        {show && (
          <div className="about-us-nav">
            {data?.slice(0, 4)?.map((item) => {
              const { id, text, link } = item;
              return (
                <SidebarLinks
                  onClick={() => {
                    dispatch(toggleCloseSidebar());
                    navigate(link);
                  }}
                  key={id}
                >
                  {text}
                </SidebarLinks>
              );
            })}
          </div>
        )}
        <div className="underline" />
        {data?.slice(-4)?.map((item) => {
          const { id, text, link } = item;
          return (
            <SidebarLinks
              className="underline"
              onClick={() => {
                dispatch(toggleCloseSidebar());
                navigate(link);
              }}
              key={id}
            >
              {text}
            </SidebarLinks>
          );
        })}
        <div className="btn">
          <Button
            primary
            capitalize
            text="View Listings"
            pointer
            onClick={() => {
              navigate(AppRoutes.listings);
              dispatch(toggleCloseSidebar());
            }}
          />
        </div>
      </div>
      <div className="overlay" onClick={() => dispatch(toggleCloseSidebar())} />
    </Container>
  );
};

export default Sidebar;

const Container = styled.div`
  .btn {
    margin: 1rem 0.5rem 0.5rem 27.5px;
  }
  .overlay {
    position: fixed;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(
      99.11deg,
      rgba(49, 66, 79, 0.71) 0.83%,
      rgba(10, 11, 12, 0.64) 100%
    );
    z-index: 150008;
    display: none;

    @media screen and (max-width: 820px) {
      display: ${({ showSidebar }) => (showSidebar ? "block" : "none")};
    }
  }
  .sidebar {
    position: fixed;
    left: 0;
    height: 100vh;
    width: 270px;
    background: #fff;
    z-index: 1500100;
    display: none;

    @media screen and (max-width: 820px) {
      display: ${({ showSidebar }) => (showSidebar ? "block" : "none")};
    }

    .header {
      display: flex;
      width: 100%;
      padding: 12px 27.5px 12px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #efefef;

      img {
        width: 80px;
        height: 40px;
      }
    }
  }

  .underline {
    border-bottom: 1px solid #efefef;
  }

  .about-us-nav {
    padding-left: 1rem;
  }
`;

const ToggleBtn = styled.img`
  cursor: pointer;
  width: 13px !important;
  height: 13px !important;

  :hover {
    transform: scale(1.1);
  }
`;

// const SidebarLinks = styled(Link)`
const SidebarLinks = styled.div`
  width: 100%;
  padding: 12px 27.5px 12px;
  // font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #505960;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    opacity: 0.7;
  }
`;
