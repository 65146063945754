import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    sidebarActive: false,
  },
  reducers: {
    toggleOpenSidebar: (state) => {
      state.sidebarActive = true;
      return state;
    },
    toggleCloseSidebar: (state) => {
      state.sidebarActive = false;
      return state;
    },
  },
});

export const { toggleOpenSidebar, toggleCloseSidebar } = sidebarSlice.actions;

export const sidebarSelector = (state) => state.sidebar;
