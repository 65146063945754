import React, { lazy, Suspense, useEffect } from "react";
import Layout from "../layout";
import { toggleActiveTab } from "../redux/reducers/navbar";
import { useDispatch } from "react-redux";
import LazyLoadComp from "../components/LazyLoadComp";

const ListingDetails = lazy(() => import("../components/Listings/ListingDetails"));


const ViewListingDetails = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleActiveTab("Project"));
  }, [dispatch]);

  return (
    <Layout
      content={
        <Suspense fallback={<LazyLoadComp />}>
          <ListingDetails />
        </Suspense>
      }
    />
  );
};

export default ViewListingDetails;
