import React, { lazy, Fragment, useEffect, Suspense } from "react";
import Layout from "../layout";
import { toggleActiveTab } from "../redux/reducers/navbar";
import { useDispatch } from "react-redux";

const Hero = lazy(() => import("../components/Home/Hero"));
const Services = lazy(() => import("../components/Home/Services"));
const Properties = lazy(() => import("../components/Home/Properties"));
const HeroAboutUs = lazy(() => import("../components/Home/HeroAboutUs"));
const Partners = lazy(() => import("../components/Home/Partners"));
const MapSection = lazy(() => import("../components/Home/MapSection"));
const BlogSection = lazy(() => import("../components/Home/BlogSection"));
const Testimonails = lazy(() => import("../components/Home/Testimonials"));

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleActiveTab("Home"));
  }, [dispatch]);

  return (
    <Layout
      content={
        <Fragment>
          <Hero />
          <Suspense fallback={<div />}>
            <HeroAboutUs />
            <Services />
            <Partners />
            <Properties />
            <MapSection />
            <BlogSection />
            <Testimonails />
          </Suspense>
        </Fragment>
      }
    />
  );
};

export default Home;
