import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  MailIcon,
  PhoneIcon,
  LocationIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  YoutubeIcon,
} from "../assets/icons/footer";
// import { FooterBg } from "../assets/images/backgrounds";
import Logo from "../assets/Logo-orange.svg";
import { AppLinks, AppRoutes } from "../services/constants";

const Footer = () => {
  return (
    <Container>
      <MainFooter>
        <Subscribe data-aos="fade-left" data-aos-duration="1000">
          <h3> subscribe to our newsletter</h3>
          <div className="flex">
            <input type="email" placeholder="Enter Your Email Address" />
            <button>SUBSCRIBE</button>
          </div>
        </Subscribe>
        <FooterText>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="logo_group"
          >
            <img
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="logo"
              src={Logo}
              alt=""
            />
            <div className="group">
              <img src={LocationIcon} alt="" />
              <h4 className="cap">
                7th floor mulliner towers, 39 alfred
                <br /> rewane road, ikoyi-lagos
              </h4>
            </div>
            <div className="group">
              <img src={MailIcon} alt="" />
              <a href="mailto:info@brixenmore.com">info@brixenmore.com</a>
            </div>
            <div className="phone_number_wrapper">
              <img src={PhoneIcon} alt="" />
              <div className="phone_number_group">
                <a href="tel:+23412797542">
                  <h4>+23412797542</h4>
                </a>
                <a href="tel:+2347093496941">
                  <h4>+2347093496941</h4>
                </a>
                <a href="tel:+23407002749366673">
                  <h4>0700BRIXENMORE</h4>
                </a>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="links_group"
          >
            <h4>Company</h4>
            {[
              { id: 1, text: "Home", link: AppRoutes.home },
              { id: 2, text: "Community", link: AppRoutes.home },
              { id: 3, text: "Blog", link: AppRoutes.blog },
              { id: 4, text: "Why Brixenmore?", link: AppRoutes.whoWeAre },
              { id: 5, text: "Listings", link: AppRoutes.listings },
            ].map((item) => {
              const { id, text, link } = item;
              return (
                <LinkR key={id} to={link}>
                  {text}
                </LinkR>
              );
            })}
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="links_group"
          >
            <h4>Quick Links</h4>
            {[
              { id: 1, text: "About Us", link: AppRoutes.whoWeAre },
              { id: 2, text: "Our Blog", link: AppRoutes.home },
              { id: 3, text: "Mortgage", link: "/" },
              { id: 4, text: "Real Estate", link: "/" },
              { id: 5, text: "Listings", link: AppRoutes.listings },
              { id: 6, text: "Privacy Policy", link: "/" },
            ].map((item) => {
              const { id, text, link } = item;
              return (
                <LinkR key={id} to={link}>
                  {text}
                </LinkR>
              );
            })}
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="follow_us"
          >
            <h4>Follow Us</h4>
            <div className="group">
              {[
                {
                  id: 1,
                  text: "Facebook",
                  link: AppLinks.facebook,
                  icon: FacebookIcon,
                },
                {
                  id: 2,
                  text: "Instagram",
                  link: AppLinks.instagram,
                  icon: InstagramIcon,
                },
                {
                  id: 3,
                  text: "Linkedin",
                  link: AppLinks.linkedin,
                  icon: LinkedinIcon,
                },
                {
                  id: 4,
                  text: "Twitter",
                  link: AppLinks.twitter,
                  icon: TwitterIcon,
                },
                {
                  id: 5,
                  text: "Youtube",
                  link: AppLinks.youtube,
                  icon: YoutubeIcon,
                },
              ].map((item) => {
                const { id, icon, text, link } = item;
                return (
                  <a key={id} href={link} target="_blank" rel="noreferrer">
                    <img src={icon} alt={text} />
                  </a>
                );
              })}
            </div>
          </div>
        </FooterText>
      </MainFooter>
      <Copyright>
        &copy; copyright 2022 brixenmore properties limited. all rights reserved
      </Copyright>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  postition: relative;
  // height: 648px;
  width: 100%;
`;

const MainFooter = styled.div`
  z-index: 99999;
  position: relative;
  min-height: 584px;
  width: 100%;
  padding: 0 150px 208px;
  background: #0f1a24
    url("https://res.cloudinary.com/devbafat/image/upload/v1661951594/footer-bg_ernvgk.png")
    no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;

  @media screen and (max-width: 1024px) {
    padding: 0 3rem;
  }

  @media screen and (max-width: 425px) {
    padding: 0 1rem 3rem;
    background-attachment: scroll !important;
  }
`;

const Subscribe = styled.div`
  width: 100%;
  // height: 85px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: #2d4f6c;
  padding: 10px 48px;

  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    padding: 10px;
  }

  .flex {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 80% 20%;
    gap: 10px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 75% 25%;
    }
  }

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: left;

    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 23px;
    background: #345977;
    box-shadow: 0px 7px 13px rgba(52, 89, 119, 0.2);
    width: 100%;
    height: 49px;
    border: none;
    cursor: pointer;
    font-family: Carla Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: #ffffff;

    :hover,
    :focus {
      border: none;
      outline: none;
      opacity: 0.7;
    }

    @media screen and (max-width: 425px) {
      height: 29px;
      font-size: 12px;
    }
  }

  input {
    width: 100%;
    height: 40px;
    padding: 0 1.3rem;
    border: 1.5px solid #fff;
    box-sizing: border-box;
    background: transparent;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    color: #ffffff;
    opacity: 0.7;

    @media screen and (max-width: 425px) {
      height: 29px;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #ffffff;
      opacity: 1; /* Firefox */
    }

    :hover,
    :focus {
      //   border: none;
      outline: none;
    }
  }
`;
const LinkR = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #f1f1f1 !important;

  :hover {
    color: #fcd7ad !important;
  }

  img {
    width: 40px;
    height: 40px;
  }
`;

const FooterText = styled.div`
  display: flex;
  margin: 69px 0 0;
  // gap: 8.5rem;
  justify-content: space-between;

  .phone_number_wrapper {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    width: 70%;
  }

  .phone_number_group {
    display: flex;
    gap: 10px;
    // align-items: center;
    flex-wrap: wrap;

    a,
    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      // line-height: 141.4%;
      // text-transform: capitalize;
      color: #fefefe !important;
      margin: 0 !important;
      padding: 0 !important;
      // text-wrap:

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }
    }

    a {
      :hover {
        color: #fcd7ad !important;
      }
    }


  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 2rem;
  }

  .links_group {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #f1f1f1;
      // margin-bottom: 20px !important;

      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }
    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #f1f1f1;
      // margin-bottom: 16px !important;

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }
    }
  }

  .follow_us {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media screen and (max-width: 425px) {
      gap: 1rem;
    }
    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #f1f1f1;
      margin-bottom: 1rem;

      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }

    .group {
      display: flex;
      gap: 1.5rem;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
      @media screen and (max-width: 425px) {
        flex-direction: row;
        gap: 1rem;
      }
      img {
        cursor: pointer;
        width: 40px;
        height: 40px;

        @media screen and (max-width: 425px) {
          width: 24px;
          height: 24px;
        }

        :hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .logo_group {
    display: flex;
    flex-direction: column;
    //   align-items: center;
    justify-content: center;

    .logo {
      width: 127px;
      height: 58.05px;
      margin-bottom: 2rem !important;

      @media screen and (max-width: 425px) {
        width: 91px;
        height: 42px;
      }
    }

    .group {
      display: flex;
      align-items: center !important;
      gap: 1rem;
      margin-bottom: 1.5rem;

      @media screen and (max-width: 425px) {
        margin-bottom: 1rem;
      }

      img {
        width: 24px;
        height: 24px;

        @media screen and (max-width: 425px) {
          width: 18px;
          height: 18px;
        }
      }

      a,
      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        // line-height: 141.4%;
        // text-transform: capitalize;
        color: #fefefe !important;
        margin: 0 !important;
        padding: 0 !important;
        // text-wrap:

        @media screen and (max-width: 425px) {
          font-size: 14px;
        }
      }

      a {
        :hover {
          color: #fcd7ad !important;
        }
      }
    }
  }
`;

const Copyright = styled.div`
  height: 64px;
  width: 100%;
  background: #2d4f6c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #ffffff;

  @media screen and (max-width: 425px) {
    padding: 0 1.5rem;
    text-align: center;
  }
`;
