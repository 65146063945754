import { configureStore } from "@reduxjs/toolkit";
import { sidebarSlice } from "./reducers/sidebar";
import { navbarSlice } from "./reducers/navbar";
import { projectSlice } from "./reducers/project";
import { blogPostSlice } from "./reducers/blogPost";
import { listingsSlice } from "./reducers/listings";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const blogPostPersistConfig = {
  key: "blogPost",
  storage,
  whitelist: ["selectedBlogPost"],
};

const listingPersistConfig = {
  key: "listing",
  storage,
  whitelist: ["selectedListing"],
};

const projectPersistConfig = {
  key: "project",
  storage,
  whitelist: ["selectedProject"],
};

const reducer = {
  sidebar: sidebarSlice.reducer,
  navbar: navbarSlice.reducer,
  project: persistReducer(projectPersistConfig, projectSlice.reducer),
  listings: persistReducer(listingPersistConfig, listingsSlice.reducer),
  blogPost: persistReducer(blogPostPersistConfig, blogPostSlice.reducer),
};

export default configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
