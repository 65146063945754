import React from 'react';
import styled from 'styled-components';

const Content = (props) => {
  return <Container>{props.content}</Container>;
};

export default Content;

const Container = styled.div`
  position: relative;
  top: 115px;
  width: 100%;
  min-height: calc(100vh);
  margin-bottom: 80px;
`;
