import { createSlice } from "@reduxjs/toolkit";

export const navbarSlice = createSlice({
  name: "navbar",
  initialState: {
    activeTab: "Home",
    subNav: false,
  },
  reducers: {
    toggleActiveTab: (state, { payload }) => {
      state.activeTab = payload;
      return state;
    },
    toggleShowSubNav: (state) => {
      state.subNav = !state.subNav;
      return state;
    },
  },
});

export const { toggleActiveTab, toggleShowSubNav } = navbarSlice.actions;

export const navbarSelector = (state) => state.navbar;
