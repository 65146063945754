import React, { Fragment, lazy, Suspense, useEffect } from "react";
// import { Content, Hero } from "../components/ViewListings";
import Layout from "../layout";
import { toggleActiveTab } from "../redux/reducers/navbar";
import { useDispatch } from "react-redux";
import LazyLoadComp from "../components/LazyLoadComp";

const Hero = lazy(() => import("../components/ViewListings/Hero"));
const Content = lazy(() => import("../components/ViewListings/Content"));
const ViewListings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleActiveTab("Listings"));
  }, [dispatch]);

  return (
    <Layout
      content={
        <Suspense fallback={<LazyLoadComp />}>
          <Fragment>
            <Hero />
            <Content />
          </Fragment>
        </Suspense>
      }
    />
  );
};

export default ViewListings;
