import React, { Fragment, Suspense, lazy, useEffect } from "react";
import Layout from "../layout";
import { toggleActiveTab } from "../redux/reducers/navbar";
import { useDispatch } from "react-redux";
import LazyLoadComp from "../components/LazyLoadComp";

const Hero = lazy(() => import("../components/Blog/Hero"));
const Body = lazy(() => import("../components/Blog/Body"));

const Blog = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleActiveTab("Blog"));
  }, [dispatch]);

  return (
    <Layout
      content={
        <Suspense fallback={<LazyLoadComp />}>
          <Fragment>
            <Hero />
            <Body />
          </Fragment>
        </Suspense>
      }
    />
  );
};

export default Blog;
