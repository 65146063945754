import { createSlice } from "@reduxjs/toolkit";
import { projects } from "../../database";

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    activeTab: "all",
    selectedProject: null,
    projects,
  },
  reducers: {
    toggleActiveTab: (state, { payload }) => {
      state.activeTab = payload;
      return state;
    },
    handleSelectedProject: (state, { payload }) => {
      state.selectedProject = payload;
      return state;
    },
  },
});

export const { toggleActiveTab, handleSelectedProject } = projectSlice.actions;

export const projectSelector = (state) => state.project;
