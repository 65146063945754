import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleOpenSidebar } from "../redux/reducers/sidebar";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MailIcon,
  PhoneIcon,
  TwitterIcon,
  ChevronDown,
  HamburgerMenu,
} from "../assets/icons/navbar";
import Logo from "../assets/Logo.svg";
import {
  navbarSelector,
  toggleActiveTab,
  toggleShowSubNav,
} from "../redux/reducers/navbar";
import { Button } from "../reusables";
import { AppLinks, AppRoutes } from "../services/constants";
import SubNav from "./SubNav";

const socialIcons = [
  {
    id: 1,
    name: "facebook",
    icon: FacebookIcon,
    link: AppLinks.facebook,
  },
  {
    id: 2,
    name: "linkedin",
    icon: LinkedinIcon,
    link: AppLinks.linkedin,
  },
  {
    id: 3,
    name: "instagram",
    icon: InstagramIcon,
    link: AppLinks.instagram,
  },
  {
    id: 4,
    name: "twitter",
    icon: TwitterIcon,
    link: AppLinks.twitter,
  },
];

const navLinks = [
  { id: 1, text: "Home", link: AppRoutes.home },
  { id: 2, text: "About Us", link: "/about-us", isDropDown: true },
  { id: 3, text: "Our Services", link: AppRoutes.ourServices },
  { id: 4, text: "Projects", link: AppRoutes.project },
  { id: 5, text: "Blog", link: AppRoutes.blog },
  { id: 6, text: "Contact Us", link: AppRoutes.contactUs },
];

const Navbar = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { activeTab, subNav } = useSelector(navbarSelector);
  const [selectedTab, setSelectedTab] = useState("Home");

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  const [onScroll, setOnScroll] = React.useState(false);

  const handleNavScroll = () => {
    if (window.scrollY >= 115) {
      setOnScroll(true);
    } else {
      setOnScroll(false);
    }
  };

  window.addEventListener("scroll", handleNavScroll);
  return (
    <Container>
      <InfoNav>
        <div className="contact">
          <a href="tel:+23407002749366673">
            <img src={PhoneIcon} alt="Vercel Logo" />
            Call Us Now : 0700BRIXENMORE
          </a>
          <div className="divider">|</div>
          <a href="mailto:info@brixenmore.com">
            <img src={MailIcon} alt="Vercel Logo" />
            info@brixenmore.com
          </a>
        </div>
        <div className="social-icons">
          {socialIcons.map((item) => {
            const { id, name, icon, link } = item;
            return (
              <a key={id} href={link}>
                <img src={icon} alt={name} />
              </a>
            );
          })}
        </div>
      </InfoNav>
      <MainNav onScroll={onScroll}>
        <Link to={AppRoutes.home}>
          <img src={Logo} alt="Logo" />
        </Link>
        <div className="nav-group">
          {navLinks.map((item) => {
            const { id, text, link, isDropDown } = item;
            return (
              <NavItem
                onClick={() => {
                  isDropDown && dispatch(toggleShowSubNav());
                  dispatch(toggleActiveTab(text));
                }}
                className={selectedTab === text ? "active" : ""}
                key={id}
                to={!isDropDown && link}
              >
                {text} {isDropDown && <img src={ChevronDown} alt="arrow" />}
              </NavItem>
            );
          })}
          <Button
            primary
            Capitalize
            text="View Listings"
            pointer
            onClick={() => {
              Navigate(AppRoutes.listings);
              dispatch(toggleActiveTab("LISTINGS"));
            }}
          />
        </div>
        <Menu onClick={() => dispatch(toggleOpenSidebar())} />
      </MainNav>
      {subNav && <SubNav />}
    </Container>
  );
};

export default Navbar;

const Container = styled.div`
  position: fixed !important;
  top: 0px !important;
  left: 0;
  height: 115px;
  z-index: 100000;
  width: 100vw;

  .active {
    border-top: 2px solid #506c85;
  }
`;

const InfoNav = styled.div`
  height: 36px;
  width: 100%;
  background: #d5dce2;
  padding: 0 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1280px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 425px) {
    padding: 0 1rem;
  }

  @media screen and (max-width: 320px) {
    .divider {
      display: none;
    }
  }

  .social-icons {
    display: flex;
    gap: 1rem;
    align-items: center;
    @media screen and (max-width: 820px) {
      display: none;
    }
    a {
      :hover {
        transform: scale(1.1);
      }
    }
  }

  .contact {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #506c85;

    @media screen and (max-width: 820px) {
      gap: 0.45rem;
    }

    @media screen and (max-width: 400px) {
      font-size: 0.8em;
    }

    @media screen and (max-width: 320px) {
      flex-direction: column-reverse;
      gap: 0.15em;
      align-items: flex-start;
    }

    a {
      display: flex;
      gap: 0.5em;
      align-items: center;
    }
  }
`;

const MainNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 79px;
  width: 100%;
  padding: 0 150px;
  background: ${({ onScroll }) =>
    onScroll ? "#fff" : "rgba(165, 179, 189, 0.75)"};
  box-shadow: ${({ onScroll }) => (onScroll ? "0 4px 2px -2px #efefef" : "")};

  @media screen and (max-width: 1280px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 400px) {
    padding: 0 1rem;
  }

  .nav-group {
    display: flex;
    align-items: center;
    gap: 48px;
    height: 100%;

    @media screen and (max-width: 1224px) {
      gap: 2rem;
    }

    @media screen and (max-width: 1024px) {
      gap: 1.5rem;
    }

    @media screen and (max-width: 868px) {
      display: none;
    }
  }
`;

export const NavItem = styled(Link)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  justify-content: center;
  text-transform: capitalize;
  color: #2d506c !important;
  cursor: pointer;
  height: 100%;
  max-width: 120px;

  img {
    width: 10px;
    height: 10px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }

  :hover {
    opacity: 0.7;
  }
`;

const Menu = styled(HamburgerMenu)`
  width: 1.5rem !important;
  height: 1.5rem !important;

  @media screen and (min-width: 868px) {
    display: none;
  }

  @media screen and (max-width: 868px) {
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: block;

    :hover {
      transform: scale(1.1);
    }
  }
`;
