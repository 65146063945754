import { createSlice } from "@reduxjs/toolkit";

export const blogPostSlice = createSlice({
  name: "blogPost",
  initialState: {
    selectedBlogPost: null,
  },
  reducers: {
    handleSelectedBlogPost: (state, { payload }) => {
      state.selectedBlogPost = payload;
      return state;
    },
  },
});

export const { handleSelectedBlogPost } = blogPostSlice.actions;

export const blogPostSelector = (state) => state.blogPost;
