import React, { Fragment, lazy, Suspense, useEffect } from "react";
import Layout from "../layout";
// import {
//   Hero,
//   Body,
//   SubSection,
//   Offices,
//   SubHero,
// } from "../components/WhoWeAre";
import { toggleActiveTab } from "../redux/reducers/navbar";
import { useDispatch } from "react-redux";
import LazyLoadComp from "../components/LazyLoadComp";

const Hero = lazy(() => import("../components/WhoWeAre/Hero"));
const Body = lazy(() => import("../components/WhoWeAre/Body"));
const SubSection = lazy(() => import("../components/WhoWeAre/SubSection"));
const Offices = lazy(() => import("../components/WhoWeAre/Offices"));
const SubHero = lazy(() => import("../components/WhoWeAre/SubHero"));

const WhoWeAre = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleActiveTab("About Us"));
  }, [dispatch]);

  return (
    <Layout
      content={
        <Suspense fallback={<LazyLoadComp />}>
          <Fragment>
            <Hero />
            <Body />
            <SubSection />
            <Offices />
            <SubHero />
          </Fragment>
        </Suspense>
      }
    />
  );
};

export default WhoWeAre;
