import React, { lazy, Fragment, Suspense, useEffect } from "react";
import Layout from "../layout";
// import { ContactBox, Hero } from "../components/ContactUs";
import { toggleActiveTab } from "../redux/reducers/navbar";
import { useDispatch } from "react-redux";
import LazyLoadComp from "../components/LazyLoadComp";

const Hero = lazy(() => import("../components/ContactUs/Hero"));
const ContactBox = lazy(() => import("../components/ContactUs/ContactBox"));

const ContactUs = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleActiveTab("Contact Us"));
  }, [dispatch]);
  return (
    <Layout
      content={
        <Suspense fallback={<LazyLoadComp />}>
          <Fragment>
            <Hero />
            <ContactBox />
          </Fragment>
        </Suspense>
      }
    />
  );
};

export default ContactUs;
