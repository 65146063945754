export const AppRoutes = {
  home: "/",
  blog: "/blog",
  whoWeAre: "/who-we-are",
  howWeWork: "/how-we-work",
  ourCulture: "/our-culture",
  ourServices: "/our-services",
  csr: "/csr",
  contactUs: "/contact-us",
  listings: "/listings",
  project: "/project",
};

export const AppLinks = {
  facebook: "https://web.facebook.com/brixenmore",
  instagram: "https://www.instagram.com/brixenmore",
  linkedin: "https://www.linkedin.com/company/brixenmore-properties-limited/",
  twitter: "https://twitter.com/brixenmore",
  youtube: "https://www.youtube.com/channel/UCkj_0RhkJ1-Fd4tWv6jp69w",
};

export const dateFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};
