import React, { Suspense } from "react";
// import Head from 'next/head';
import Navbar from "./Navbar";
import Content from "./Content";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { sidebarSelector } from "../redux/reducers/sidebar";
import LazyLoadComp from "../components/LazyLoadComp";

const Index = (props) => {
  const { sidebarActive } = useSelector(sidebarSelector);
  return (
    <Container id="container" showSidebar={sidebarActive}>
      <Suspense fallback={<LazyLoadComp />}>
        <Sidebar />
        <Navbar />
        <Content content={props.content} />
        <Footer />
      </Suspense>
    </Container>
  );
};

export default Index;

const Container = styled.div`
  height: ${({ showSidebar }) => (showSidebar ? "100vh" : "")} !important;
  overflow: ${({ showSidebar }) => (showSidebar ? "hidden" : "")} !important;
`;
