import React from "react";
import styled from "styled-components";

const ErrorHandler = (props) => {
  return (
    <Container>
      <div>
        <h2>Something went wrong...</h2>
      </div>
      <p>{props?.error?.message}</p>
      <button
        type="button"
        onClick={() => {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          // window.history.back();
        }}
      >
        Reset
      </button>
    </Container>
  );
};

export default ErrorHandler;

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  button {
    padding: 0.25rem 2rem;
    background: transparent;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
  }
`;
