import React from "react";
import styled, { css } from "styled-components";
import { DotsLoadingAnimation } from "./index";
import { ArrowRight } from "../assets/icons/navbar";
import { MdFileDownload } from "react-icons/md";

const Button = (props) => {
  return (
    <Container
      type={props.type}
      full={props.full ? props.full : undefined}
      large={props.large ? props.large : undefined}
      primary={props.primary}
      light={props.light ? props.light : undefined}
      outline={props.outline ? props.outline : undefined}
      outlineLight={props.outlineLight ? props.outlineLight : undefined}
      outlinePrimary={props.outlinePrimary ? props.outlinePrimary : undefined}
      secondary={props.secondary ? props.secondary : undefined}
      onClick={props.onClick}
      disabled={props.disabled ? true : false}
      className={props.className}
      capitalize={props.capitalize}
      pointer={props.pointer}
      download={props.download}
      customFont={props.customFont ? true : false}
    >
      {props.loading ? <DotsLoadingAnimation /> : props.text}
      {props.pointer && (
        <ArrowRight
          fill={
            props?.primary
              ? "#fff"
              : props?.outline
              ? "#fff"
              : props?.outlineLight
              ? "#fff"
              : "#2D506C"
          }
          className="icon"
        />
      )}
      {props.download && <MdFileDownload className="download" />}
    </Container>
  );
};

export default Button;

const Container = styled.button`
  position: relative;
  width: ${({ full }) => (full ? "100%" : "200px")};
  height: ${({ large }) => (large ? "60px" : "42px")};
  display: flex;
  gap: 1rem;
  justify-content: ${({ download }) =>
    download ? "flex-start" : "space-between"};
  align-items: center;
  border: none;
  font-family:${({ customFont }) =>
    customFont ? "Carla Sans !important" : "Ubuntu"}
  font-style: normal;
  font-weight: 500;
  padding:0 20px;
  font-size: 14px;
  line-height: 16px;
  text-transform: ${({ capitalize }) =>
    capitalize ? "capitalize" : "uppercase"};
  letter-spacing: 0.05em;
  cursor: pointer;

  @media screen and (max-width: 425px) {
    height: ${({ large }) => (large ? "45px" : "35px")};
  }

  ${(props) => css`
    ${props.primary &&
    css`
      background: #2d506c;
      color: #fff;
    `}

    ${props.secondary &&
    css`
      background: #fcd7ad;
      color: #2d506c;
    `}

    ${props.outline &&
    css`
      background: transparent;
      color: #2d506c;

      :hover {
        background: #2d506c4a;
      }
    `}

    ${props.outlineLight &&
    css`
      background: transparent;
      color: #fff;

      :hover {
        background: #ffffff4a;
      }
    `}
    ${props.outlinePrimary &&
    css`
      background: transparent;
      color: #2d506c;

      :hover {
        background: #2d506c4a;
      }
    `}
    ${props.light &&
    css`
      background: #82d7cd;
      color: #fff;
    `}
  `}

  .icon {
    width: 14px;
    height: 9px;
  }

  .download {
    width: 18px;
    height: 18px;
  }

  :hover{
    opacity:0.8;
  }
  
  :focus {
    outline: none;
  }

  .spinner {
    margin: 0px auto 0;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner > .blue {
    width: 15px;
    height: 15px;
    background-color: #ffffff !important;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;
