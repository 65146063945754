import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { toggleShowSubNav } from "../redux/reducers/navbar";
import { AppRoutes } from "../services/constants";
import { NavItem } from "./Navbar";

const SubNav = () => {
  const dispatch = useDispatch();
  return (
    <Container>
      {[
        { text: "Who we are", link: AppRoutes.whoWeAre },
        { text: "How we work", link: AppRoutes.howWeWork },
        { text: "Our culture", link: AppRoutes.ourCulture },
        { text: "CSR", link: AppRoutes.csr },
      ].map((item, index) => {
        const { text, link } = item;
        return (
          <NavItem
            key={index}
            to={link}
            onClick={() => dispatch(toggleShowSubNav())}
          >
            {text}
          </NavItem>
        );
      })}
    </Container>
  );
};

export default SubNav;

const Container = styled.div`
  position: absolute;
  top: 115px;
  width: 100%;
  height: 48px;
  background: rgba(165, 179, 189, 0.8);
  z-index: 100001;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 63px;

  .active {
    border-top: 2px solid #506c85;
  }
`;
