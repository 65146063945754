import React from "react";
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import {
  Home,
  WhoWeAre,
  HowWeWork,
  ContactUs,
  ViewListings,
  CSR,
  Project,
  OurCulture,
  Services,
  ViewListingDetails,
  ViewProjectDetails,
  Blog,
  BlogPost,
} from "./pages";
import { AppRoutes } from "./services/constants";
import Aos from "aos";
import ScrollToTop from "./helpers/ScrollToTop";

function App() {
  React.useEffect(() => {
    Aos.init();
  });
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={AppRoutes.home} element={<Home />} />
          <Route path={AppRoutes.whoWeAre} element={<WhoWeAre />} />
          <Route path={AppRoutes.howWeWork} element={<HowWeWork />} />
          <Route path={AppRoutes.csr} element={<CSR />} />
          <Route path={AppRoutes.ourCulture} element={<OurCulture />} />
          <Route path={AppRoutes.blog} element={<Blog />} />
          <Route path={`${AppRoutes.blog}/:title`} element={<BlogPost />} />
          <Route path={AppRoutes.contactUs} element={<ContactUs />} />
          <Route path={AppRoutes.listings} element={<ViewListings />} />
          <Route
            path={`${AppRoutes.listings}/:id`}
            element={<ViewListingDetails />}
          />
          <Route
            path={`${AppRoutes.project}/:id`}
            element={<ViewProjectDetails />}
          />
          <Route path={AppRoutes.project} element={<Project />} />
          <Route path={AppRoutes.ourServices} element={<Services />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
