import React, { Suspense, lazy, useEffect } from "react";
import Layout from "../layout";
import { toggleActiveTab } from "../redux/reducers/navbar";
import { useDispatch } from "react-redux";
// import ProjectDetails from "../components/Projects/ProjectDetails";
import LazyLoadComp from "../components/LazyLoadComp";

const ProjectDetails = lazy(() =>
  import("../components/Projects/ProjectDetails")
);

const ViewProjectDetails = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleActiveTab("Project"));
  }, [dispatch]);

  return (
    <Layout
      content={
        <Suspense fallback={<LazyLoadComp />}>
          <ProjectDetails />
        </Suspense>
      }
    />
  );
};

export default ViewProjectDetails;
