import { createSlice } from "@reduxjs/toolkit";
import { projects } from "../../database";

export const listingsSlice = createSlice({
  name: "listings",
  initialState: {
    listings: projects,
    selectedListing: null,
  },
  reducers: {
    handleSelectedlisting: (state, { payload }) => {
      state.selectedListing = payload;
      return state;
    },
  },
});

export const { handleSelectedlisting } = listingsSlice.actions;

export const listingsSelector = (state) => state.listings;
