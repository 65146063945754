import React, { Fragment, lazy, Suspense, useEffect } from "react";
import Layout from "../layout";
// import { Hero, Body } from "../components/HowWeWork";
import { toggleActiveTab } from "../redux/reducers/navbar";
import { useDispatch } from "react-redux";
import LazyLoadComp from "../components/LazyLoadComp";

const Hero = lazy(() => import("../components/HowWeWork/Hero"));
const Body = lazy(() => import("../components/HowWeWork/Body"));

const WhoWeAre = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleActiveTab("About Us"));
  }, [dispatch]);

  return (
    <Layout
      content={
        <Suspense fallback={<LazyLoadComp />}>
          <Fragment>
            <Hero />
            <Body />
          </Fragment>
        </Suspense>
      }
    />
  );
};

export default WhoWeAre;
