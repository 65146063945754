import React from "react";
import styled from "styled-components";
import Logo from "../assets/Logo.svg";

const LazyLoadComp = () => {
  return (
    <Container>
      <img src={Logo} alt="Brixenmore-Logo" />
      <h3>Loading...</h3>
    </Container>
  );
};

export default LazyLoadComp;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;

  img {
    width: auto;
    height: auto;
  }

  h3 {
    font-style: normal;
    font-weight: 300;
    justify-content: center;
    text-transform: capitalize;
    color: #2d506c !important;
  }
`;
