/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
// import { BsEye, BsEyeSlashFill } from 'react-icons/bs';

const Container = styled.div`
  position: relative;
  width: 100%;
  // min-width:320px;

  .group {
    display: flex;
    align-items: center;
    position: relative;
    height: 57px;

    label {
      position: absolute;
      top: 10px;
      left: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 11px;
      text-transform: uppercase;
      color: #172836;

      span {
        color: #f14c18;
        font-size: 12px;
      }
    }

    .icon {
      position: absolute;
      right: 0;
      transform: translateX(-50%);
      margin-right: 1em;
      // width: 1rem;
      cursor: pointer;
      font-weight: 500;
      font-size: 13px;
      color: #939598;
    }

    input {
      border: none;
      padding: 1.5em 15px 10px;
      height: 100%;
      //   border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 1em;
      letter-spacing: 0.018em;
      color: #333;
      width: 100%;
      ${(props) => css`
        ${props.primary &&
        css`
          background: #f1f0ef;
          border: none;
        `}
        ${props.outline &&
        css`
          background: transparent;
          border: 1px solid #e5e5e5;
        `}
      `}

      :focus {
        outline: none;
        border: 1px solid #bdbdbd;
      }
    }
  }
`;
const Index = ({
  label,
  inputType,
  placeholder,
  onTextChange,
  fieldname,
  primary,
  outline,
}) => {
  const [togglePassword, setTogglePassword] = useState(false);
  return (
    <Container primary={primary} outline={outline ? outline : ''}>
      <div className='group'>
        <label>
          {label}
          <span> *</span>
        </label>
        {inputType === 'password' ? (
          togglePassword ? (
            <p className='icon' onClick={() => setTogglePassword(false)}>
              Show
            </p>
          ) : (
            <p className='icon' onClick={() => setTogglePassword(true)}>
              Hide
            </p>
          )
        ) : (
          ''
        )}
        {inputType === 'password' ? (
          <input
            name={fieldname}
            type={!togglePassword ? 'password' : 'text'}
            placeholder={placeholder}
            onChange={onTextChange}
          />
        ) : (
          <input
            name={fieldname}
            type={inputType}
            placeholder={placeholder}
            onChange={onTextChange}
          />
        )}
      </div>
    </Container>
  );
};

export default Index;
